<template>
  <v-app>
    <!-- 右側のAppBar -->
    <dashboard-core-app-bar />

    <!-- 左側のドロワー -->
    <dashboard-core-drawer />

    <!-- 右側のメインコンテンツ -->
    <dashboard-core-view />

    <!-- 右側のスクロールバーのやつ -->
    <!-- <dashboard-core-settings /> -->
  </v-app>
</template>

<script>
export default {
  name: "DashboardIndex",

  components: {
    DashboardCoreAppBar: () => import("./components/core/AppBar"),
    DashboardCoreDrawer: () => import("./components/core/Drawer"),
    DashboardCoreView: () => import("./components/core/View"),
    // DashboardCoreSettings: () => import("./components/core/Settings"),
  },

  data: () => ({
    expandOnHover: false,
  }),
};
</script>
